import Map from "ol/Map.js";
import View from "ol/View.js";
import Zoomify from "ol/source/Zoomify.js";
import ZoomSlider from "ol/control/ZoomSlider.js";
import ZoomIn from "ol/control/ZoomIn.js";
import ZoomOut from "ol/control/ZoomOut.js";
import FullScreen from "ol/control/FullScreen.js";
import ToolsBar from "ol/control/ToolsBar.js";
import Magnifier from "ol/control/Magnifier.js";
import CellList from "ol/control/CellList.js";
import HistoryInfo from "ol/control/HistoryInfo.js";
import Point from "ol/geom/Point.js";
//import {Icon, Stroke, Style} from 'ol/style.js';
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from "ol/style.js";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";
import { Vector as VectorSource } from "ol/source.js";
import { DragZoom, defaults as defaultInteractions } from "ol/interaction.js";
import {
  OverviewMap,
  DrawVector,
  ZoomToExtent,
  ScaleLine,
  defaults as defaultControls,
} from "ol/control.js";
import { Draw, Modify, Select, Snap } from "ol/interaction.js";
import { never } from "ol/events/condition.js";
import GeoJSON from "ol/format/GeoJSON.js";

var imgWidth = null;
var imgHeight = null;
var tileSize = 256;

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
var imagepath = null;

const sys_name = "vheqam."
const api_domain = "induckelec.com"
const geojsonserver = "https://" + sys_name +  api_domain + "/api";

var geojsonObject = null;
var login = false;
var api_token = "118e5104-2743-44d3-9f7d-83c0e7897fac";
var uuid = "result";

var admin_mode = false;
/*
function readXml2(xmlFile){
  var xmlDoc;
  var xmlhttp = new XMLHttpRequest();
  xmlhttp.open("GET",xmlFile, false);
  
  if (xmlhttp.overrideMimeType){
      xmlhttp.overrideMimeType('text/xml');
  }
  
  xmlhttp.send();
  if (xmlhttp.status !== 200) {
    return;
  }
  xmlDoc=xmlhttp.responseXML;
  console.log(xmlDoc);
  const tagObj = xmlDoc.getElementsByTagName("IMAGE_PROPERTIES");
  imgWidth = tagObj[0].getAttribute("WIDTH");
  imgHeight = tagObj[0].getAttribute("HEIGHT");
  tileSize = tagObj[0].getAttribute("TILESIZE");
}
*/
async function readXml(xmlFile) {
  return fetch(xmlFile)
    .then((response) => response.text())
    .then((str) => new window.DOMParser().parseFromString(str, "text/xml"))
    .then((data) => {
      const tagObj = data.getElementsByTagName("IMAGE_PROPERTIES");
      imgWidth = tagObj[0].getAttribute("WIDTH");
      imgHeight = tagObj[0].getAttribute("HEIGHT");
      tileSize = tagObj[0].getAttribute("TILESIZE");
    });
}

async function readGeoJson(GeoJsonURL) {
  return fetch(GeoJsonURL, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: api_token,
      uuid: uuid,
    },
    method: "GET",
  })
    .then((response) => response.json())
    .then((data) => {
      geojsonObject = data;
      //console.log(geojsonObject);
    });
}
/*
async function verificationLogin(authen) {
  const Cookie =
    "username=" + authen.username + "; access_token=" + authen.access_token;
  return fetch(authen.url, {
    method: "GET",
    headers: {
      "X-Cookie": Cookie,
    },
  })
    .then((response) => response.status)
    .then((data) => {
      if (data == 200) login = true;
      else login = false;
    });
}
*/
function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const username = getCookie("username");
const access_token = getCookie("access_token");
//console.log("username: " + username);
//console.log("access_token: " + access_token);

const authen = {
  username: username,
  access_token: access_token,
  url: "https://" + sys_name + api_domain + "/api/access_token",
};
/*
await verificationLogin(authen);
if (login == false) {
  window.location.replace("https://www." + api_domain + "/mhd/member/login");
}
*/
if (urlParams.has("image")) {
  try {
    imagepath = "image/" + urlParams.get("image");
    await readXml(imagepath + "/ImageProperties.xml");
  } catch {}
}

if (urlParams.has("admin")) {
  admin_mode = true;
}

if (imgWidth !== null && imgHeight !== null) {
  await readGeoJson(geojsonserver + "?image=" + urlParams.get("image"));

  const cen_imgWidth = imgWidth / 2;
  const cen_imgHeight = imgHeight / 2;

  const zoomifyUrl = imagepath + "/";

  const source = new Zoomify({
    url: zoomifyUrl,
    size: [imgWidth, imgHeight],
    tileSize: tileSize,
    crossOrigin: "anonymous",
    zDirection: 0,
    cacheSize: 2048,
  });

  const extent = source.getTileGrid().getExtent();

  const layer = new TileLayer({
    source: source,
  });

  /*
  const styleFunction = function (feature) {
    const geometry = feature.getGeometry();
    const styles = [
      // linestring
      new Style({
        stroke: new Stroke({
          color: '#ffcc33',
          width: 2,
        }),
      }),
    ];
  
    geometry.forEachSegment(function (start, end) {
      const dx = end[0] - start[0];
      const dy = end[1] - start[1];
      const rotation = Math.atan2(dy, dx);
      // arrows
      styles.push(
        new Style({
          geometry: new Point(end),
          image: new Icon({
            src: 'data/arrow.png',
            anchor: [0.75, 0.5],
            rotateWithView: true,
            rotation: -rotation,
          }),
        }),
      );
    });
  
    return styles;
  };

  const vector = new VectorLayer({
    source: new VectorSource(),
    style: styleFunction,
  });
  */

  var vectorSource;
  if (geojsonObject != null) {
    try {
      var geojson = new GeoJSON({
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      }).readFeatures(geojsonObject);

      vectorSource = new VectorSource({
        features: geojson,
      });
    } catch {
      vectorSource = new VectorSource();
    }
  } else {
    vectorSource = new VectorSource();
  }

  const labelStyle = new Style({
    text: new Text({
      font: "14px Calibri,sans-serif",
      fill: new Fill({
        color: "#000",
      }),
      stroke: new Stroke({
        color: "#fff",
        width: 4,
      }),
      overflow: true,
    }),
  });
  const countryStyle = new Style({
    fill: new Fill({
      color: "rgba(255, 255, 255, 0)",
    }),
    stroke: new Stroke({
      color: "#70C0FF",
      width: 2,
    }),
    image: new CircleStyle({
      radius: 5,
      fill: new Fill({
        color: "rgba(255, 255, 255, 0.5)",
      }),
      troke: new Stroke({
        color: "#70C0FF",
        width: 2,
      }),
    }),
  });
  const style = [countryStyle, labelStyle];

  const vector = new VectorLayer({
    source: vectorSource,
    style: function (feature) {
      labelStyle.getText().setText([` ${feature.get("name")}`, ""]);
      return style;
    },
    renderers: ["Canvas", "VML"],
  });

  const maxZoomLevel = layer.getSource().getTileGrid().getResolutions().length;

  const overviewMapControl = new OverviewMap({
    className: "ol-overviewmap ol-custom-overviewmap",

    layers: [
      new TileLayer({
        source: source,
      }),
      //vector,
    ],

    view: new View({
      extent: extent,
      minZoom: Math.floor(14 - maxZoomLevel / 2),
      maxZoom: Math.floor(14 - maxZoomLevel / 2),
      zoom: Math.floor(14 - maxZoomLevel / 2),

      center: [0, 0],
    }),

    imgWidth: imgWidth,
    imgHeight: imgHeight,
    maxZoomLevel: maxZoomLevel,

    collapseLabel: "\u00BB",
    label: "\u00AB",
    collapsed: true,
  });

  let control;

  function scaleControl() {
    control = new ScaleLine({
      units: "metric",
    });

    return control;
  }

  const map = new Map({
    controls: defaultControls({
      zoomOptions: [maxZoomLevel],
    }).extend([
      overviewMapControl,
      new DrawVector({
        extent: extent,
        label: "D",
        tipLabel: "Draw",
      }),

      new ZoomToExtent({
        extent: extent,
        label: "\u{1F3E0}",
        tipLabel: "Reset view",
      }),

      scaleControl(),
    ]),
    interactions: defaultInteractions({
      altShiftDragRotate: false,
      pinchRotate: false,
    }).extend([new DragZoom()]),
    //interactions: defaultInteractions({altShiftDragRotate:false, pinchRotate:false}).extend([new DragZoom()]),
    layers: [layer, vector],
    target: "map",
    view: new View({
      resolutions: layer.getSource().getTileGrid().getResolutions(),
      extent: extent,
      constrainOnlyCenter: true,
      center: [cen_imgWidth, cen_imgHeight],
      zoom: 8,
      minZoom: 2,
    }),
  });

  const VectorModify = {
    init: function () {
      this.select = new Select({
        toggleCondition: never,
      });
      map.addInteraction(this.select);

      this.modify = new Modify({
        features: this.select.getFeatures(),
      });
      map.addInteraction(this.modify);

      this.setEvents();
    },
    setEvents: function () {
      const selectedFeatures = this.select.getFeatures();

      this.select.on("change:active", function () {
        selectedFeatures.forEach(function (each) {
          selectedFeatures.remove(each);
        });
      });
    },
    delSelect: function () {
      const selectedFeatures = this.select.getFeatures();
      if (selectedFeatures.getLength() > 0) {
        vector.getSource().removeFeature(selectedFeatures.item(0));
      }
    },
    getActive: function () {
      const selectedFeatures = this.select.getFeatures();
      if (selectedFeatures.getLength() > 0) {
        return true;
      }
      return false;
    },
    setSelect: function (active) {
      this.select.setActive(active);
    },
    setModify: function (active) {
      this.modify.setActive(active);
    },
    setActive: function (active) {
      this.select.setActive(active);
      this.modify.setActive(active);
    },
  };

  const VectorDraw = {
    init: function () {
      map.addInteraction(this.Point);
      this.Point.setActive(false);
      map.addInteraction(this.LineString);
      this.LineString.setActive(false);
      map.addInteraction(this.Polygon);
      this.Polygon.setActive(false);
      map.addInteraction(this.Circle);
      this.Circle.setActive(false);
    },
    Point: new Draw({
      source: vector.getSource(),
      type: "Point",
    }),
    LineString: new Draw({
      source: vector.getSource(),
      type: "LineString",
    }),
    Polygon: new Draw({
      source: vector.getSource(),
      type: "Polygon",
    }),
    Circle: new Draw({
      source: vector.getSource(),
      type: "Circle",
    }),
    activeDraw: null,
    setActive: function (active) {
      if (this.activeDraw) {
        this.activeDraw.setActive(false);
        this.activeDraw = null;
      }
      if (active) {
        //const type = optionsForm.elements['draw-type'].value;
        this.activeDraw = this["Circle"];
        this.activeDraw.setActive(true);
      }
    },
  };

  /**
   * Let user change the geometry type.
   * @param {Event} e Change event.
   */
  /*
  optionsForm.onchange = function (e) {
    const type = e.target.getAttribute('name');
    if (type == 'draw-type') {
      VectorModify.setActive(false);
      VectorDraw.setActive(true);
      optionsForm.elements['interaction'].value = 'draw';
    } else if (type == 'interaction') {
      const interactionType = e.target.value;
      if (interactionType == 'modify') {
        VectorDraw.setActive(false);
        VectorModify.setActive(true);
      } else if (interactionType == 'draw') {
        VectorDraw.setActive(true);
        VectorModify.setActive(false);
      }
    }
  };
  */
  
  var putGeoJson = function () {
    var mapLayers = map.getLayers().getArray();
    var features = [];
    mapLayers.forEach(function (layer) {
      if (layer instanceof VectorLayer) {
        var layerFeatures = layer.getSource().getFeatures();
        layerFeatures.forEach(function (feature) {
          feature.setProperties(layer.getStyle()); //add the layer styles to the feature as properties
          features.push(feature);
        });
      }
    });
    var geojsonFormat = new GeoJSON();
    var geojsonString = geojsonFormat.writeFeatures(features, {
      featureProjection: map.getView().getProjection(),
    });
    //console.log(geojsonString);

    (async () => {
      const rawResponse = await fetch(geojsonserver + "?image=" + urlParams.get("image"), {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          token: api_token,
          uuid: uuid,
        },
        body: geojsonString,
      });

      const response = await rawResponse;
      if (response.status != 201) alert("Unable to save data");
      //console.log(response.status);
    })();
  };

  var deleteFeature = function (evt) {
    if (evt.keyCode == 46 || evt.keyCode == 8) {
      if (VectorModify.getActive()) {
        VectorModify.delSelect();
        VectorModify.setSelect(false);
        VectorModify.setSelect(true);
        putGeoJson();
      }
    }
  };

  const selectSingleClick = new Select();
  let select = selectSingleClick; // ref to currently selected interaction

  const changeInteraction = function () {
    if (select !== null) {
      map.addInteraction(select);
      select.on("select", function (e) {
        if (e.target.getFeatures().getLength() == 0) {
          putGeoJson();
        }
      });
    }
  };

  if(admin_mode){
    document.addEventListener("keydown", deleteFeature, false);
    changeInteraction();
    VectorModify.init();
    VectorDraw.init();
    VectorDraw.setActive(false);
    VectorModify.setActive(true);
  }

  // The snap interaction must be added after the Modify and Draw interactions
  // in order for its map browser event handlers to be fired first. Its handlers
  // are responsible of doing the snapping.

  const snap = new Snap({
    source: vector.getSource(),
  });
  map.addInteraction(snap);

  map.getView().fit(extent);
  const zoomslider = new ZoomSlider();
  map.addControl(zoomslider);

  const zoomin = new ZoomIn();
  map.addControl(zoomin);

  const zoomout = new ZoomOut();
  map.addControl(zoomout);

  const fullscreen = new FullScreen();
  map.addControl(fullscreen);

  const magnifier = new Magnifier({
    maxZoomLevel: maxZoomLevel,
  });
  map.addControl(magnifier);

  const toolsbar = new ToolsBar({
    url: "/data/manual.pdf",
    admin: admin_mode
  });
  map.addControl(toolsbar);

  const historyinfo = new HistoryInfo({
    url: imagepath + "/history.html",
  });
  map.addControl(historyinfo);

  const celllist = new CellList({
    geojsonObject: geojsonObject,
    maxZoomLevel: maxZoomLevel,
  });
  map.addControl(celllist);

  document.addEventListener("contextmenu", (event) => event.preventDefault());

  //map.addLayer(vectorLayer);
} else {
  window.location.replace("https://www.fsoftprodev.xyz/mhd/");
  /*
  const container = document.getElementById("mapcontainer");
  container.style.width = 0;
  container.style.height = 0;

  var img = document.createElement("img");
  img.className = "image404";
  img.id = "image404";
  img.src = "data/404-code.png";

  img.style.margin = "auto";
  img.style.position = "absolute";
  img.style.top = "0";
  img.style.bottom = "0";
  img.style.left = "0";
  img.style.right = "0";
  img.style.maxHeight = "100%";
  img.style.maxWidth = "100%";

  document.body.appendChild(img);
  document.addEventListener("contextmenu", (event) => event.preventDefault());
  */
}
